.action-pills {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 5px;
    height:50px;
    vertical-align: middle;
}
.pill {
    background: rgb(255,255,255);
    cursor: pointer;
    padding: 0 16px;
    height: 50px !important;
    line-height: 50px;
    border: .5px solid grey;
}

.selected {
    background: rgb(0, 122, 255);
    color: rgb(255, 255, 255);
    padding: 0 16px;
    height: 50px !important;
    line-height: 50px;
}

:first-child {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

:last-child {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}