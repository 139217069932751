body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    background-color: #F2F2F6;
}

.active,
.item-hilight:hover {
    background-color: #007aff;
    color: #FEFEFE;
}

/* Begin Text Definitions */
.service-title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
}

.service-heading {
    color: black;
    font-size: 2.3rem;
    font-weight: bold;
}

.service-subheading {
    color: black;
    font-size: 1.0rem;
    font-weight: bold;
}

.service-bodytext {
    color: black;
    font-size: 0.875rem;
    line-height: 0.75rem;
    font-weight: normal;
}

.service-footnote {
    color: black;
    font-size: 0.825rem;
    line-height: 0.825rem;
    font-style: italic;
}


.service-form-label,
.labsupplies-form-label {
    color: black;
    font-size: 1.18rem;
    line-height: 3.5rem;
    font-weight: normal;
    white-space: nowrap;
}
.service-form-control {
    height: 3rem !important;
    font-size: 1.2rem !important;
}

.service-form-label2 {
    color: black;
    font-size: 1.18rem;
    font-weight: normal;
    word-wrap: break-word;
}

.labsupplies-form-label {
    line-height: 1rem;
}

.reports-label,
.reports-text {
    font-size: 0.825rem;
    line-height: 0.825rem;
    font-weight: normal;
}

.reports-text {
    font-weight: bold;
}

.item-name {
    width: 350px;
    text-align: left;
    font-style: normal;
}

.item-value {
    width: 90px;
    text-align: right;
}

.item-value-head {
    /* width: 90px; */
    text-align: right;
    font-style: bold;
}

.item-name-head {
    /* width: 200px; */
    text-align: left;
    font-style: bold;
}

.form-value {
    width: 90px;
    text-align: right;
}

/* End Text Definitions */




/* Begin Button Definitions */
.button-standard-primary,
.button-standard-secondary {
    background-color: #007aff;
    box-shadow: 1px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    height: 44px;
    border: none;
    border-radius: 9px;
    color: #FEFEFE;
    padding: 0px 32px;
    vertical-align: center;
    text-align: center;
    font-weight: bold;
    text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    margin: 4px 2px;
    cursor: pointer;
}

.button-standard-secondary {
    background-color: #EEEEEF;
    font-weight: normal;
}

.button-pill,
.button-pill-small,
.button-pill-caution {
    background-color: #EEEEEF;
    box-shadow: 1px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    height: 30px;
    border: none;
    border-radius: 22px;
    /* color: black; */
    padding: 0px 18px;
    vertical-align: center;
    text-align: center;
    font-weight: normal;
    text-decoration: none;
    display: inline-block;
    font-size: 0.875rem;
    color: #007aff;
    margin: 4px 2px;
    cursor: pointer;
}

.button-pill-small {
    height: 24px;
}

.button-pill-caution {
    color: #FF3B30;
}

.button-max-primary,
.button-max-secondary {
    background-color: #007aff;
    box-shadow: 1px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    height: 44px;
    width: 100%;
    border: none;
    border-radius: 9px;
    color: #FEFEFE;
    padding: 0px 0px;
    vertical-align: center;
    text-align: center;
    /* font-weight: bold; */
    text-decoration: none;
    display: inline-block;
    font-size: 1.3rem;
    cursor: pointer;
    margin-top: 20px;
}

.button-max-secondary {
    background-color: #EEEEEF;
    font-weight: normal;
    color: black;
}

/* End Button Definitions */




/* Begin Card Definitions */
.card {
    background: #FEFEFE;
    border-radius: 9px;
    border-color: lightgray;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);
    overflow: auto;
    margin-top: 0px;
    margin-bottom: 4px;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 1180px;
}



.freeze-pane {
    overflow: hidden;
}

/* End Card Definitions */



.ST-navbar {
    background: #FEFEFE;
    border-radius: 0px;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);;
}

.ST-bottom-navbar {
    background: #FEFEFE;
    border-radius: 0;
    min-height: 40px;
}

.ST-menu-icon {
    margin-right: "30px"

}


.list-group-scroll {
    max-height: 200px;
    min-height: 200px;
    margin-bottom: 10px;
    overflow: scroll;
    border: 1px;
}

.list-group-scroll-reports {
    max-height: 100vh;
    overflow-y: auto;
    margin-bottom: 100px;
    border: 1px;
}

.select-report {
    height: 1.5rem;
    font-size: 0.625rem;

}

.login-wrapper {
    padding: 18px;
    margin: 0 auto;
    position: fixed;
    border-radius: 6px;
    overflow: hidden;
    top: 50%;
    left: 50%;
    min-width: 250px;
    transform: translate(-50%, -50%);
}

.align-center {
    text-align: center;
}

.content-routes{
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding-top: 64px;
}

.details-box{
    border: .5px solid gray;
    margin-top: 15px;
    height: 260px;
    width: 100% !important;
    overflow: scroll;
}

.table-detail{
    border: .2px solid gainsboro;
    white-space:nowrap !important;
}
.checkbox-col{
    text-align: center !important;
}

.form-group{
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
}

.form-group-label {
    margin-top: 8px;
}