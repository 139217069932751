.ST-navbar {
    background: #FEFEFE;
    border-radius: 0px;
    box-shadow: 0px 1px 2px 0px hsla(0, 0%, 0%, 0.2);;
}

.center-txt{
    display: flex;
    align-items: center;
    height: 60px;
}
.back-link {
    color: black !important;
    text-decoration: none!important;
    background-color: transparent !important;
    border: transparent !important;
    outline: transparent!important;
}